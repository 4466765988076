.report-container {
  position: relative;
  margin-left: 196px;
}

.report-container iframe{
  border: none;
  height: calc(100vh - 108px) !important;
}

.report-container:after{
  content: '';
  position: absolute;
  bottom: 4px;
  right: 32px;
  height: 36px;
  width: 220px;
  background-color: #eaeaea;
}
