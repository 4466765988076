@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
    :root {
        --background: #ffffff;
        --foreground: #0f172a;
    
        --muted: 	#f1f5f9;
        --muted-foreground: #64748b;
    
        --popover: #ffffff;
        --popover-foreground: #344054;
    
        --border: #eaecf0;
        --input: #D0D5DD;
    
        --card: #ffffff;
        --card-foreground:#0f172a;
    
        --primary: #0f172a;
        --primary-foreground: #f8fafc;
    
        --secondary: 	#f1f5f9;
        --secondary-foreground: #0f172a;
    
        --accent: #f1f5f9;
        --accent-foreground: 	#0f172a;
    
        --destructive: #ff0000;
        --destructive-foreground: 	#f8fafc;
    
        --ring: #94a3b8;
    
        --radius: 0.5rem;

        --overlay: rgba(12, 17, 29, 0.8);

        --utility-gray-50: #F9FAFB;
        --utility-gray-100: #F2F4F7;
        --utility-gray-200: #EAECF0;
        --utility-gray-300: #D0D5DD;
        --utility-gray-400: #98A2B3;
        --utility-gray-500: #667085;
        --utility-gray-600: #475467;
        --utility-gray-700: #344054;
        --utility-gray-800: #182230;
        --utility-gray-900: #101828;

        --utility-brand-50: #E4DCF9;
        --utility-brand-100: #C9B9F3;
        --utility-brand-200: #AE96ED;
        --utility-brand-300: #9273E7;
        --utility-brand-400: #5C2EDC;
        --utility-brand-500: #4B21C0;
        --utility-brand-600: #3D1B9D;
        --utility-brand-700: #30157A;
        --utility-brand-800: #230F58;
        --utility-brand-900: #140934;

        --utility-error-50: #FEF3F2;
        --utility-error-100: #FEE4E2;
        --utility-error-200: #FECDCA;
        --utility-error-300: #FDA29B;
        --utility-error-400: #F97066;
        --utility-error-500: #F04438;
        --utility-error-600: #D92D20;
        --utility-error-700: #B42318;

        --utility-warning-50: #FFFAEB;
        --utility-warning-100: #FEF0C7;
        --utility-warning-200: #FEDF89;
        --utility-warning-300: #FEC84B;
        --utility-warning-400: #FDB022;
        --utility-warning-500: #F79009;
        --utility-warning-600: #DC6803;
        --utility-warning-700: #B54708;

        --utility-success-50: #ECFDF3;
        --utility-success-100: #DCFAE6;
        --utility-success-200: #ABEFC6;
        --utility-success-300: #75E0A7;
        --utility-success-400: #47CD89;
        --utility-success-500: #17B26A;
        --utility-success-600: #079455;
        --utility-success-700: #067647;

        --utility-gray-blue-50: #F8F9FC;
        --utility-gray-blue-100: #EAECF5;
        --utility-gray-blue-200: #D5D9EB;
        --utility-gray-blue-300: #B3B8DB;
        --utility-gray-blue-400: #717BBC;
        --utility-gray-blue-500: #4E5BA6;
        --utility-gray-blue-600: #3E4784;
        --utility-gray-blue-700: #363F72;

        --utility-blue-light-50: #F0F9FF;
        --utility-blue-light-100: #E0F2FE;
        --utility-blue-light-200: #B9E6FE;
        --utility-blue-light-300: #7CD4FD;
        --utility-blue-light-400: #36BFFA;
        --utility-blue-light-500: #0BA5EC;
        --utility-blue-light-600: #0086C9;
        --utility-blue-light-700: #026AA2;

        --utility-blue-50: #EFF8FF;
        --utility-blue-100: #D1E9FF;
        --utility-blue-200: #B2DDFF;
        --utility-blue-300: #84CAFF;
        --utility-blue-400: #53B1FD;
        --utility-blue-500: #2E90FA;
        --utility-blue-600: #1570EF;
        --utility-blue-700: #175CD3;

        --utility-dark-blue-50: #EFF4FF;
        --utility-dark-blue-100: #D1E0FF;
        --utility-dark-blue-200: #B2CCFF;
        --utility-dark-blue-300: #84ADFF;
        --utility-dark-blue-400: #528BFF;
        --utility-dark-blue-500: #2970FF;
        --utility-dark-blue-600: #155EEF;
        --utility-dark-blue-700: #004EEB;

        --utility-indigo-50: #EEF4FF;
        --utility-indigo-100: #E0EAFF;
        --utility-indigo-200: #C7D7FE;
        --utility-indigo-300: #A4BCFD;
        --utility-indigo-400: #6172F3;
        --utility-indigo-500: #6172F3;
        --utility-indigo-600: #444CE7;
        --utility-indigo-700: #3538CD;

        --utility-purple-50: #F4F3FF;
        --utility-purple-100: #EBE9FE;
        --utility-purple-200: #D9D6FE;
        --utility-purple-300: #BDB4FE;
        --utility-purple-400: #9B8AFB;
        --utility-purple-500: #7A5AF8;
        --utility-purple-600: #6938EF;
        --utility-purple-700: #5925DC;

        --utility-fuchsia-50: #FDF4FF;
        --utility-fuchsia-100: #FBE8FF;
        --utility-fuchsia-200: #F6D0FE;
        --utility-fuchsia-300: #EEAAFD;
        --utility-fuchsia-400: #E478FA;
        --utility-fuchsia-500: #D444F1;
        --utility-fuchsia-600: #BA24D5;
        --utility-fuchsia-700: #9F1AB1;

        --utility-pink-50: #FDF2FA;
        --utility-pink-100: #FCE7F6;
        --utility-pink-200: #FCCEEE;
        --utility-pink-300: #FAA7E0;
        --utility-pink-400: #F670C7;
        --utility-pink-500: #EE46BC;
        --utility-pink-600: #DD2590;
        --utility-pink-700: #C11574;
        
        --utility-orange-dark-50: #FFF4ED;
        --utility-orange-dark-100: #FFE6D5;
        --utility-orange-dark-200: #FFD6AE;
        --utility-orange-dark-300: #FF9C66;
        --utility-orange-dark-400: #FF692E;
        --utility-orange-dark-500: #FF4405;
        --utility-orange-dark-600: #E62E05;
        --utility-orange-dark-700: #BC1B06;

        --utility-orange-50: #FEF6EE;
        --utility-orange-100: #FDEAD7;
        --utility-orange-200: #F9DBAF;
        --utility-orange-300: #F7B27A;
        --utility-orange-400: #F38744;
        --utility-orange-500: #EF6820;
        --utility-orange-600: #E04F16;
        --utility-orange-700: #B93815;
    }
}

@layer base {
    * {
      @apply border-border;
    }
    body {
      @apply bg-background text-foreground;
      font-feature-settings: "rlig" 1, "calt" 1;
    }
    .date-picker-hide::-webkit-calendar-picker-indicator {
      @apply hidden;
      width: 0px !important;
    }
}

@layer base {
    * {
        margin: 0;
        padding: 0;
        box-sizing: border-box;
        -webkit-user-drag: none;
        touch-action: manipulation;
        -webkit-overflow-scrolling: touch;
        -webkit-tap-highlight-color: transparent;
        font-family: 'Avenir', sans-serif;
    }
    body {
        margin: 0;
        overflow: auto;
        background-color: #eeeff0;
    }
    button,
    input,
    optgroup,
    select,
    textarea {
        font-family: inherit;
        font-feature-settings: inherit;
        font-variation-settings: inherit;
        font-size: 14px;
        font-weight: normal;
        line-height: inherit;
        letter-spacing: inherit;
        color: inherit;
    }
    h1,
    h2,
    h3,
    h4,
    h5,
    h6 {
        font-size: inherit;
        font-weight: bold;
    }
}